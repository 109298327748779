import { useStorage } from '@vueuse/core';

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    onboardingData: useStorage(
      'onboardingProfileData',
      {} as { [key: string]: string | string[] }
    ),
    onboardingInviteToken: useStorage(
      'onboardingInviteToken',
      undefined as string | undefined
    ),
    onboardingInviteEmail: useStorage(
      'onboardingInviteEmail',
      undefined as string | undefined
    ),
    verifyingInvitation: false,
  }),

  hydrate(storeState) {
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.onboardingData = useStorage(
      'onboardingProfileData',
      {} as { [key: string]: string | string[] }
    );
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.onboardingInviteEmail = useStorage('onboardingInviteEmail', '');
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.onboardingInviteToken = useStorage('onboardingInviteToken', '');
  },

  actions: {
    saveInvitation(token: string, email: string) {
      this.onboardingInviteToken = token;
      this.onboardingInviteEmail = email;
    },

    saveOnboardingStepData(stepData: { [key: string]: string | string[] }) {
      const existingData = this.onboardingData || {};

      this.onboardingData = { ...existingData, ...stepData };
    },

    removeOnboardingStepData(stepKey: string) {
      const existingData = this.onboardingData || {};

      if (stepKey in existingData) {
        delete existingData[stepKey];
        this.onboardingData = { ...existingData };
      }
    },

    resetOnboardingData() {
      this.onboardingData = {};
    },
  },
});
